import { Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as waves from 'node-waves';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked, OnInit {

  template$: Observable<AppTemplates>;
  loading$: Subject<string>;

  constructor(
    private translateServ: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingServ: LoadingService,
    private cdRef: ChangeDetectorRef
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateServ.setDefaultLang('pt-br');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateServ.use('pt-br');

    this.listenTemplate();
    this.initWavesEffect();

    // Set the loading$ to listen which loading show or hide all
    this.loading$ = this.loadingServ.loading$;
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  private listenTemplate() {
    this.template$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute.snapshot),
      map(routeSnapshot => routeSnapshot.root.firstChild.data.template || 'main')
    );
  }

  private initWavesEffect() {
    waves.attach('.waves-effect', ['waves-button']);
    waves.attach('.waves-light', ['waves-light']);
    waves.init();
  }

}

export enum AppTemplates {
  main = 'main',
  blank = 'blank'
}
