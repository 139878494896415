import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/api/auth.service';
import { LocalStorage } from 'src/app/decorators/local-storage.decorator';
import { IUserLogged } from 'src/app/models/user-adm/user-logged.interface';
import { IService } from 'src/app/models/service/service.interface';
import { ServiceService } from 'src/app/services/fire/service/service.service';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'c-core-navbar',
  templateUrl: './core-navbar.component.html',
  styleUrls: ['./core-navbar.component.scss']
})
export class CoreNavbarComponent implements OnInit {

  isShowMenu: boolean;
  myServices:IService[] = []
  services:ServiceService
  @LocalStorage('user') user: IUserLogged;

  constructor(private authServ: AuthService, private serviceService:ServiceService) { 
    this.services = serviceService;
  }

  async ngOnInit() {
    this.myServices = await this.services.services$.pipe(first()).toPromise();
  }

  showMenu(e: Event) {
    e.stopPropagation();
    this.isShowMenu = !this.isShowMenu;
  }

  closeMenu() {
    this.isShowMenu = false;
  }

  logout() {
    this.authServ.logout();
  }

  formatNameOrEmail(value: string) {
    const separatedValue = value ? value.split(' ') : [];
    if (separatedValue.length > 0) {
      return separatedValue[0];
    }
    return value;
  }

}
