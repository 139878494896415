import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { MomentModule } from 'angular2-moment';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgxMaskModule } from 'ngx-mask';
import { PipesModule } from '../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    TranslateModule.forChild(),
    PipesModule,
    FilterPipeModule,
    NgxMaskModule.forRoot(),
    DirectivesModule,
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    TranslateModule,
    PipesModule,
    FilterPipeModule,
    NgxMaskModule,
    DirectivesModule,
  ]
})
export class AppSharedModule { }
