import { Injectable, Inject } from '@angular/core';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService, LoadingName, Loading } from './loading.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { IUserLogged } from '../models/user-adm/user-logged.interface';
import { LocalStorage } from '../decorators/local-storage.decorator';

@Injectable({
  providedIn: 'root'
})
export class FirebaseClientService {

  @LocalStorage('user') user: IUserLogged;
  private requestErrorsTranslate: any;

  constructor(
    private functions: AngularFireFunctions,
    private toastrServ: ToastrService,
    private translateServ: TranslateService,
    private loadingServ: LoadingService,
    private db: AngularFirestore,
    private storage: AngularFireStorage
  ) {
    this.translateServ.get('request errors').subscribe(res => {
      this.requestErrorsTranslate = res;
    });
  }

  do<T>(p: Promise<T>, options?: FirebaseClientOptions) {
    const mergedOptions = this.mergeWithDefaultOptions(options);
    return from(p).pipe(
      this.catchRequestErrorHandler(),
      this.toastHandler(mergedOptions),
      this.loadingHandler(mergedOptions)
    );
  }

  call<T>(functionName: string, data?: any, options?: FirebaseClientOptions): Observable<T> {
    const mergedOptions = this.mergeWithDefaultOptions(options);
    return this.functions.httpsCallable(functionName)(data)
      .pipe(
        this.catchRequestErrorHandler(),
        this.toastHandler(mergedOptions),
        this.loadingHandler(mergedOptions)
      );
  }

  collection<T>(collectionName: string, options?: FirebaseClientOptions) {
    const mergedOptions = this.mergeWithDefaultOptions(options);
    return this.db.collection<T>(collectionName).valueChanges()
      .pipe(
        this.catchRequestErrorHandler(),
        this.toastHandler(mergedOptions),
        this.loadingHandler(mergedOptions)
      );
  }

  doc<T>(collectionName: string, id: string, options?: FirebaseClientOptions) {
    const mergedOptions = this.mergeWithDefaultOptions(options);
    return this.db.collection(collectionName).doc<T>(id).valueChanges()
      .pipe(
        this.catchRequestErrorHandler(),
        this.toastHandler(mergedOptions),
        this.loadingHandler(mergedOptions)
      );
  }

  uploadFileWithoutName(folder: string, file: File, options?: FirebaseClientOptions) {
    const mergedOptions = this.mergeWithDefaultOptions(options);
    const ref = this.storage.ref(`${folder}/${this.user.id}-${new Date().getTime()}${this.getFileExtension(file.name)}`);
    return from(ref.put(file))
      .pipe(
        this.catchRequestErrorHandler(),
        this.toastHandler(mergedOptions),
        this.loadingHandler(mergedOptions)
      );
  }

  private loadingHandler<T>(options: FirebaseClientOptions) {
    let loading: Loading;
    if (options.isShowLoading) {
      loading = this.loadingServ.show(options.loadingName, 0.2);
    }

    const completeLoading = () => {
      if (loading) {
        loading.dismiss();
      }
    };

    return tap<T>(completeLoading, completeLoading);
  }

  private toastHandler<T>(options: FirebaseClientOptions) {
    return tap<T>(() => {
      if (options.isShowToast) {
        this.showToastMessage();
      }
    });
  }

  private catchRequestErrorHandler<T>() {
    return tap<T>(
      {
        error: (err: FirebaseClientHttpError) => {
          this.showToastMessage(err);
        }
      }
    );
  }

  private showToastMessage(err?: FirebaseClientHttpError) {
    if (err) {
      let errorMessage: string;

      // Set the message error
      if (err && err.details && this.requestErrorsTranslate[err.details.code]) {
        errorMessage = this.requestErrorsTranslate[err.details.code];
      } else if (err && err.code && this.requestErrorsTranslate[err.code]) {
        errorMessage = this.requestErrorsTranslate[err.code];
      } else {
        errorMessage = `${this.requestErrorsTranslate['unknow error']}.`;
      }

      this.toastrServ.error('', errorMessage, {
        positionClass: 'toast-top-center'
      });
    } else {
      this.toastrServ.success('', 'OK', {
        positionClass: 'toast-top-center',
        timeOut: 2000
      });
    }
  }

  private mergeWithDefaultOptions(options?: FirebaseClientOptions) {
    const mergedOptions = new DefaultOptions();

    if (options) {
      for (const key of Object.keys(options)) {
        mergedOptions[key] = options[key];
      }
    }

    return mergedOptions;
  }

  private getFileExtension(fileName: string) {
    return fileName ? fileName.match(/\.\w+/gi) : null;
  }
}

interface FirebaseClientOptions {
  isShowToast?: boolean;
  isShowLoading?: boolean;
  loadingName?: LoadingName | string;
}

interface FirebaseClientHttpError {
  code: string;
  details: {
    code: string;
    message: string;
  };
}

class DefaultOptions implements FirebaseClientOptions {
  isShowToast = false;
  isShowLoading = true;
  loadingName = 'pulse';
}
