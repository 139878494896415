import { Directive, Input, ViewContainerRef, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../services/api/auth.service';
import { Subscription } from 'rxjs';
import { LoggedUserService } from '../services/api/logged-user.service';

@Directive({
  selector: '[isAllowed]'
})
export class IsAllowedDirective implements OnInit, OnDestroy {

  private requiredPermission: string | string[];
  private userPermissions: string[];
  private userSubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private loggedUserServ: LoggedUserService
  ) {
    this.userSubscription = this.listenUser();
  }

  ngOnInit() {
    this.updateView();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  @Input()
  set isAllowed(value: string | string[]) {
    this.requiredPermission = value;
  }

  private listenUser() {
    return this.loggedUserServ.getUserObservable().subscribe(res => {
      this.userPermissions = res && res.roleRef ? res.roleRef.permissions : [];
      this.updateView();
    });
  }

  private updateView() {
    this.viewContainer.clear();

    if (this.checkUserHasPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private checkUserHasPermission() {
    if (this.userPermissions) {
      if (this.requiredPermission instanceof Array) {
        let permit = false;

        this.requiredPermission.forEach(permission => {
          if (this.userPermissions.find(elem => elem === permission || elem === 'olivee.*')) {
            permit = true;
          }
        });

        return permit;
      } else {
        return this.userPermissions.find(elem => elem === this.requiredPermission || elem === 'olivee.*') ? true : false;
      }

    }
    return false;
  }

}
