import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSharedModule } from './app-shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SidebarModule } from '../components/sidebar/sidebar.module';
import { NavbarModule } from '../components/navbar/navbar.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { ImgModule } from '../components/img/img.module';
import { IModule } from '../components/i/i.module';
import { CoreNavbarComponent } from './core-navbar/core-navbar.component';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { CoreSidebarComponent } from './core-sidebar/core-sidebar.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingModule } from '../components/loading/loading.module';

import { NgxMatIntlTelInputModule  } from "ngx-mat-intl-tel-input";
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig } from '@angular/material';
import { DatePipe } from '@angular/common';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    'assets/i18n/', // json path
    '.json'
  );
}

@NgModule({
  declarations: [
    AppComponent,
    CoreNavbarComponent,
    CoreSidebarComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ToastrModule.forRoot(),
    AppSharedModule,
    SidebarModule,
    NavbarModule,
    ImgModule,
    IModule,
    NgxMatIntlTelInputModule,
    NgxMaterialTimepickerModule,
    LoadingModule
  ],
  exports: [
    AppSharedModule
  ],
  providers: [
    DatePipe,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    environment.firebase.functionsProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
