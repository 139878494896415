import { Injectable } from '@angular/core';
import { LocalStorage } from '../../decorators/local-storage.decorator';
import { IUserLogged } from 'src/app/models/user-adm/user-logged.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggedUserService {

  @LocalStorage('user') private user: IUserLogged;
  private user$: BehaviorSubject<IUserLogged>;

  constructor(
  ) {
    this.user$ = new BehaviorSubject(this.user);
  }

  setUser(user: IUserLogged) {
    this.user = user;
    this.user$.next(this.user);
  }

  getUserObservable() {
    return this.user$;
  }

  getUser() {
    return this.user;
  }

}
