import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[upload]'
})
export class UploadDirective {

  private inputElement: HTMLInputElement;

  constructor(
    private elementRef: ElementRef
  ) {
    this.createInput();
  }

  @HostListener('click')
  onClick(): void {
    this.inputElement.click();
  }

  private onChangeCallback(value?: any) {
    if (this.elementRef) {
      const event = new CustomEvent('change', { detail: value });
      this.elementRef.nativeElement.dispatchEvent(event);
    }
  }

  private createInput() {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.onchange = () => {
      this.loadFile(this.inputElement.files[0]);
    };

    this.inputElement = inputElement;
  }

  private loadFile(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.onChangeCallback({
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        data: e.target.result,
        file
      });
    };

    if (file) {
      reader.readAsDataURL(file); // Read the file as base64
    } else {
      this.onChangeCallback();
    }

    this.inputElement.files = undefined;
    this.inputElement.value = '';
  }

}
