import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { tap, map } from 'rxjs/operators';
import { FirebaseClientService } from '../firebase-client.service';
import { IUserLogged } from 'src/app/models/user-adm/user-logged.interface';
import { LoggedUserService } from './logged-user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private requestErrorsTranslate: any;
  authState:Observable<any>
  uid:string = null;

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private fbClient: FirebaseClientService,
    private loggedUserServ: LoggedUserService,
    private toastrServ: ToastrService,
    private translateServ: TranslateService
  ) {
    this.isAuthenticated();
    this.checkLoginState();
    this.translateServ.get('request errors').subscribe(res => {
      this.requestErrorsTranslate = res;
    });
  }

  isAuthenticated() {
    if(this.loggedUserServ.getUser() != null){
      this.uid = this.loggedUserServ.getUser().id;
    }
    return this.loggedUserServ.getUser() != null;
  }

  login(email: string, password: string) {
    return this.fbClient.do(this.afAuth.auth.signInWithEmailAndPassword(email, password))
      .pipe(
        map((res) => res.user),
        tap(async (res) => {
          // Get the user data
          this.getUserData().subscribe(userData => {
            if (userData) {
              // Set the user data
              this.loggedUserServ.setUser(userData);
            }

            // Redirect to home
            this.router.navigateByUrl('/');
          });

          // // Check the user email is verified
          // if (res.emailVerified) {
          //   // Get the user data
          //   this.getUserData().subscribe(userData => {
          //     if (userData) {
          //       // Set the user data
          //       this.loggedUserServ.setUser(userData);
          //     }

          //     // Redirect to home
          //     this.router.navigateByUrl('/');
          //   });
          // } else {
          //   this.toastrServ.error('', this.requestErrorsTranslate['auth/email-not-verified'], {
          //     positionClass: 'toast-top-center'
          //   });

          //   await res.sendEmailVerification({ url: `${environment.appUrl}/login` });
          // }
        })
      );
  }

  async logout() {
    await this.afAuth.auth.signOut();
    this.loggedUserServ.setUser(null);
    this.router.navigateByUrl('/login');
  }

  private getUserData() {
    return this.fbClient.call<IUserLogged>('authGetUserData');
  }

  private checkLoginState() {
    let self = this;
    this.authState = this.afAuth.authState;
    this.authState.subscribe(res => {
      if (!res) {
        this.logout();
      }else{
        self.uid = res.uid;
      }
    });
  }

}
