import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpfPipe } from './cpf.pipe';
import { CnpjPipe } from './cnpj.pipe';
import { PhonePipe } from './phone.pipe';
import { SafeUrlPipe } from './safeUrl.pipe';

@NgModule({
  declarations: [
    CpfPipe,
    CnpjPipe,
    PhonePipe,
    SafeUrlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CpfPipe,
    CnpjPipe,
    PhonePipe,
    SafeUrlPipe
  ]
})
export class PipesModule { }
