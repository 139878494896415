import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { LoadingPulseComponent } from './loading-pulse/loading-pulse.component';

@NgModule({
  declarations: [
  LoadingComponent,
  LoadingPulseComponent
],
  imports: [
    CommonModule
  ],
  exports: [
    LoadingComponent,
    LoadingPulseComponent
  ]
})
export class LoadingModule { }
