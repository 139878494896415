import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { QuestionnaireModule } from '../pages/questionnaire/questionnaire/questionnaire.module';
import { AppTemplates } from './app.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('../pages/login/login.module').then(m => m.LoginModule),
    data: { template: AppTemplates.blank }
  },
  {
    path: 'home', loadChildren: () => import('../pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'dash', loadChildren: () => import('../pages/dash/dash.module').then(m => m.DashModule),
    data: { permission: 'olivee.dashboard.list' }
  },
  // Calendar routes
  {
    path: 'calendar',
    loadChildren: () => import('../pages/calendar/calendar.module').then(m => m.CalendarModule),
    data: { permission: 'olivee.dashboard.list' }
  },
  // User adm routes
  {
    path: 'user-adm',
    loadChildren: () => import('../pages/user-adm/user-adm/user-adm.module').then(m => m.UserAdmModule),
    data: { permission: 'olivee.userAdms.list' }
  },
  {
    path: 'user-adm/add',
    loadChildren: () => import('../pages/user-adm/user-adm-add-edit/user-adm-add-edit.module').then(m => m.UserAdmAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.userAdms.create' }
  },
  {
    path: 'user-adm/:id/edit',
    loadChildren: () => import('../pages/user-adm/user-adm-add-edit/user-adm-add-edit.module').then(m => m.UserAdmAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.userAdms.update' }
  },
  // Role routes
  {
    path: 'role', loadChildren: () => import('../pages/role/role/role.module').then(m => m.RoleModule),
    data: { permission: 'olivee.roles.list' }
  },
  {
    path: 'role/add',
    loadChildren: () => import('../pages/role/role-add-edit/role-add-edit.module').then(m => m.RoleAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.roles.create' }
  },
  {
    path: 'role/:id/edit',
    loadChildren: () => import('../pages/role/role-add-edit/role-add-edit.module').then(m => m.RoleAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.roles.update' }
  },
  // Company routes
  {
    path: 'company',
    loadChildren: () => import('../pages/company/company/company.module').then(m => m.CompanyModule),
    data: { permission: 'olivee.companies.list' }
  },
  {
    path: 'company/add',
    loadChildren: () => import('../pages/company/company-add-edit/company-add-edit.module').then(m => m.CompanyAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.companies.create' }
  },
  {
    path: 'company/:id/edit',
    loadChildren: () => import('../pages/company/company-add-edit/company-add-edit.module').then(m => m.CompanyAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.companies.update' }
  },
  // Subsidiary routes
  {
    path: 'subsidiary',
    loadChildren: () => import('../pages/subsidiary/subsidiary/subsidiary.module').then(m => m.SubsidiaryModule),
    data: { permission: 'olivee.subsidiaries.list' }
  },
  {
    path: 'subsidiary/add',
    loadChildren: () => import('../pages/subsidiary/subsidiary-add-edit/subsidiary-add-edit.module').then(m => m.SubsidiaryAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.subsidiaries.create' }
  },
  {
    path: 'subsidiary/:id/edit',
    loadChildren: () => import('../pages/subsidiary/subsidiary-add-edit/subsidiary-add-edit.module').then(m => m.SubsidiaryAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.subsidiaries.update' }
  },
  // Cost center routes
  {
    path: 'cost-center',
    loadChildren: () => import('../pages/cost-center/cost-center/cost-center.module').then(m => m.CostCenterModule),
    data: { permission: 'olivee.costCenters.list' }
  },
  {
    path: 'cost-center/add',
    loadChildren: () => import('../pages/cost-center/cost-center-add-edit/cost-center-add-edit.module').then(m => m.CostCenterAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.costCenters.create' }
  },
  {
    path: 'cost-center/:id/edit',
    loadChildren: () => import('../pages/cost-center/cost-center-add-edit/cost-center-add-edit.module').then(m => m.CostCenterAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.costCenters.update' }
  },
  // Employee routes
  {
    path: 'employee',
    loadChildren: () => import('../pages/employee/employee/employee.module').then(m => m.EmployeeModule),
    data: { permission: 'olivee.employees.list' }
  },
  {
    path: 'employee/add',
    loadChildren: () => import('../pages/employee/employee-add-edit/employee-add-edit.module').then(m => m.EmployeeAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.employees.create' }
  },
  {
    path: 'employee/:id/edit',
    loadChildren: () => import('../pages/employee/employee-add-edit/employee-add-edit.module').then(m => m.EmployeeAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.employees.update' }
  },
  {
    path: 'employee-doctor',
    loadChildren: () => import('../pages/employee/employee-doctor/employee-doctor.module').then(m => m.EmployeeDoctorModule),
    data: { permission: 'olivee.employees.doctorList' }
  },
  // Doctor routes
  {
    path: 'doctor',
    loadChildren: () => import('../pages/doctor/doctor/doctor.module').then(m => m.DoctorModule),
    data: { permission: 'olivee.doctors.list' }
  },
  {
    path: 'doctor/add',
    loadChildren: () => import('../pages/doctor/doctor-add-edit/doctor-add-edit.module').then(m => m.DoctorAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.doctors.create' }
  },
  {
    path: 'doctor/:id/edit',
    loadChildren: () => import('../pages/doctor/doctor-add-edit/doctor-add-edit.module').then(m => m.DoctorAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.doctors.update' }
  },
  // Questionnaire routes
  {
    path: 'questionnaire',
    loadChildren: () => import('../pages/questionnaire/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
    data: { permission: 'olivee.questionnaires.list' }
  },
  {
    path: 'questionnaire/add',
    loadChildren: () => import('../pages/questionnaire/questionnaire-add-edit/questionnaire-add-edit.module').then(m => m.QuestionnaireAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.questionnaires.create' }
  },
  {
    path: 'questionnaire/:id/edit',
    loadChildren: () => import('../pages/questionnaire/questionnaire-add-edit/questionnaire-add-edit.module').then(m => m.QuestionnaireAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.questionnaires.update' }
  },
  // Questionnaire category routes
  {
    path: 'questionnaire-category',
    loadChildren: () => import('../pages/questionnaire/questionnaire-category/questionnaire-category.module').then(m => m.QuestionnaireCategoryModule),
    data: { permission: 'olivee.questionnaires.listCategories' }
  },
  {
    path: 'questionnaire-category/add',
    // tslint:disable-next-line: max-line-length
    loadChildren: () => import('../pages/questionnaire/questionnaire-category-add-edit/questionnaire-category-add-edit.module').then(m => m.QuestionnaireCategoryAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.questionnaires.createCategory' }
  },
  {
    path: 'questionnaire-category/:id/edit',
    // tslint:disable-next-line: max-line-length
    loadChildren: () => import('../pages/questionnaire/questionnaire-category-add-edit/questionnaire-category-add-edit.module').then(m => m.QuestionnaireCategoryAddEditModule),
    data: { template: AppTemplates.blank, permission: 'olivee.questionnaires.updateCategory' }
  },
  // Medical certificate routes
  {
    path: 'medical-certificate',
    loadChildren: () => import('../pages/medical-certificate/medical-certificate.module').then(m => m.MedicalCertificateModule),
    data: { permission: 'olivee.medicalCertificates.list' }
  },
  // Telemedicine routes
  {
    path: 'telemedicine',
    loadChildren: () => import('../pages/telemedicine/telemedicine.module').then(m => m.TelemedicineModule),
    data: { permission: 'olivee.teleappointments.list' }
  },
  // Experimental routes
  {
    path: 'experimental',
    loadChildren: () => import('../pages/experimental/experimental.module').then(m => m.ExperimentalModule),
    data: { permission: '*' }
  },
  // Redirect any route not founded to home
  { path: '**', redirectTo: 'home' }
];

// Set auth guard for all routes
// If necessary remove auth guard in specific router, just pass the route name
setAuthGuardAll('login');

/**
 * Set guard in all routes except informed path
 */
function setAuthGuardAll(...exceptions: string[]) {
  routes.forEach((elem: Route) => {
    if (!exceptions.includes(elem.path)) {
      elem.canActivate = [AuthGuard];
    }
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
