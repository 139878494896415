import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * This service permit just one loading at a time
 */

// Types
export interface Loading {
  dismiss: () => void;
}

export type LoadingName = 'pulse';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loading$ = new Subject<string>();
  private loadingCount = 0;
  private timeToDisincrease = 60; // Seconds

  constructor() { }

  /**
   * Show a specific loading
   * @param name choose which loading by name
   * @param delay time in seconds
   */
  show(name: LoadingName | string, delay?: number) {
    setTimeout(() => {
      this.loading$.next(name);
      this.loadingCount++;
    }, delay);

    // Create timeout limit to cancel the loading
    const timeout = this.disincreaseAfterTime();

    // Create the loading to call dismiss
    const loading = new Object() as Loading;
    loading.dismiss = () => {
      clearTimeout(timeout);
      this.hide();
    };

    return loading;
  }

  /**
   * Try hide the loading
   */
  hide() {
     if (this.loadingCount - 1 >= 0) {
      this.loadingCount--;

      if (this.loadingCount === 0) {
        this.hideImmediately();
      }
    }
  }

  hideImmediately() {
    this.loadingCount = 0;
    this.loading$.next();
  }

  /**
   * Set the time to disincrease
   * @param time Receive the time in seconds
   */
  setTimeToDisincrease(time: number) {
    this.timeToDisincrease = time;
  }

  private disincreaseAfterTime() {
    return setTimeout(() => {
      this.hide();
    }, this.timeToDisincrease * 1000);
  }

}
