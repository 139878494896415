import { Injectable, Output, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, of } from 'rxjs';
import { first, map, filter } from 'rxjs/operators';

import { AuthService } from '../../api/auth.service';
import { ISpeciality, IService } from '../../../models/service/service.interface';
import { ITeleAppointment } from '../../../models/appointment/appointment.interface';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  specialities$:Observable<ISpeciality[]>
  services$:Observable<IService[]>
  appointments$:Observable<ITeleAppointment[]>
  requests$:Observable<ITeleAppointment[]>

  constructor(private afs: AngularFirestore, private login: AuthService, private functions: AngularFireFunctions) {

    this.specialities$ = this.afs.collection<ISpeciality>("specialties").valueChanges().pipe(
      map(speciality=>{
        return speciality.sort((a, b) => {
          return parseInt(a.code) > parseInt(b.code) ? 1 : -1;
        });
      })
    );

    if(this.login.uid !== null){
      this.services$ = this.afs.collection<IService>("services", ref=>ref.where("user","==",this.login.uid)).valueChanges();
      console.log(this.login.uid);
      this.appointments$ = this.afs.collection<ITeleAppointment>("teleappointments", ref=>ref.where("seller","==",this.login.uid)).valueChanges().pipe(
        map(requests => requests.filter(r=>typeof r.endTime == 'undefined' && r.status != 'refunded').sort((a,b)=>(a.date.toMillis() > b.date.toMillis()) ? 1 : -1))
      );
      this.requests$ = this.afs.collection<ITeleAppointment>("teleappointments", ref=>ref.where("seller","==","")).valueChanges().pipe(
        map(requests => requests
            .filter(r=>{
              if(typeof r.options !== 'undefined'){
                if(r.options.length >= 3){ return false; }
                for(let option of r.options){
                  if(option.doctor == this.login.uid){ return false; }
                }
              }
              return true;
            })
            .sort((a,b)=>(a.date.toMillis() > b.date.toMillis()) ? 1 : -1))
      );
    }

  }

  async getAppointment(id:string){
    return this.afs.doc<ITeleAppointment>(`teleappointments/${id}`).valueChanges().pipe(first()).toPromise();
  }
  async updateRequest(appointment:ITeleAppointment){
    return this.afs.doc<ITeleAppointment>(`teleappointments/${appointment.id}`).update(appointment);
  }
  async cancelRequest(appointment:ITeleAppointment){
    return this.functions.httpsCallable('cancelAppointment')({request:appointment.id}).toPromise();
  }

  async doctorScheduled(appointment:ITeleAppointment){
    return this.functions.httpsCallable('doctorScheduled')({teleappointment:appointment.id}).toPromise();
  }


  async getService(id:string){
    return this.afs.doc<IService>(`services/${id}`).valueChanges().pipe(first()).toPromise();
  }
  async updateService(service:IService){
    return this.afs.doc(`services/${service.id}`).update(service);
  }
  async saveService(service:IService){
    if(service.id == null){service.id = this.afs.createId()}
    if(service.user == null){
      service.user = this.login.uid;
    }
    return this.afs.doc(`services/${service.id}`).set(service, {merge:true});
  }

  async capturePayment(appointment:ITeleAppointment){
    return this.functions.httpsCallable('capturePayment')({appointment:appointment.id}).toPromise();
  }

  async makePayment(options:any){
    return this.functions.httpsCallable('makePayment')(options).toPromise();
  }

}
