import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from '../services/api/auth.service';
import { Observable } from 'rxjs';
import { LoggedUserService } from '../services/api/logged-user.service';

/**
 * @ignore
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  userPermissions: string[];

  constructor(
    private authServ: AuthService,
    private loggedUserServ: LoggedUserService,
    private router: Router
  ) {
    this.listenUser();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authServ.isAuthenticated()) {
      return this.checkUserHasPermission(next.data.permission);
    } else {
      this.router.navigate(['/login'], { queryParams: { redirectTo: state.url } });
      return false;
    }
  }

  private listenUser() {
    this.loggedUserServ.getUserObservable().subscribe(res => {
      this.userPermissions = res && res.roleRef ? res.roleRef.permissions : [];
    });
  }

  private checkUserHasPermission(permissionName: string) {
    if (permissionName) {
      if (this.userPermissions.find(elem => elem === permissionName || elem === 'olivee.*')) {
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }
    }

    return true;
  }

}
