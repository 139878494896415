import { FUNCTIONS_ORIGIN } from '@angular/fire/functions';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// Uncomment this block to test environment with prod
export const environment = {
  production: true,
  localStoragePrefix: 'mage',
  firebase: {
    apiKey: "AIzaSyD4ei6GMyuXvgTI8cNyqIXaf2ua5RoV7jE",
    authDomain: "mage-healthcare.firebaseapp.com",
    databaseURL: "https://mage-healthcare.firebaseio.com",
    projectId: "mage-healthcare",
    storageBucket: "mage-healthcare.appspot.com",
    messagingSenderId: "721706225472",
    appId: "1:721706225472:web:376ad131556963ad",
    functionsProvider: {
      provide: FUNCTIONS_ORIGIN, useValue: ''
    }
  },
  appUrl: window.location.origin,
  nexodata: {
    apiUrl: "https://emr.nexodata.com.br/api/prescricao/iniciar",
    modalUrl: "https://embedded.nexodata.com.br/prescricao/",
    login: "ehealth",
    password: "15eBO7XoeCdGl0",
    token: "ZWhlYWx0aDoxNWVCTzdYb2VDZEdsMA=="
  },
  quickblox: {
    credentials: {
      appId: 84198,
      authKey: 'QbyMkQREsKvQUvg',
      authSecret: 'k5gwww7rc9L9Z37',
      accountKey: '1SSqXxx5aajJgLG1C5YM'
    },
    appConfig: {
      chatProtocol: { active: 2 },
      streamManagement: { enable: true },
      debug: {
        mode: 1
      },
      webrtc: {
        answerTimeInterval: 30,
        dialingTimeInterval: 5,
        disconnectTimeInterval: 35,
        statsReportTimeInterval: 10
      }
    }
  }
};

// export const environment = {
//   production: false,
//   localStoragePrefix: 'mage_dev',
//   firebase: {
//     apiKey: 'AIzaSyA3ZjCB_ppHOL86eOQujLDrA_vIGY5LYn0',
//     authDomain: 'mage-healthcare-dev.firebaseapp.com',
//     databaseURL: 'https://mage-healthcare-dev.firebaseio.com',
//     projectId: 'mage-healthcare-dev',
//     storageBucket: 'mage-healthcare-dev.appspot.com',
//     messagingSenderId: '95655906410',
//     appId: '1:95655906410:web:cea164b7c6f20c96',
//     functionsProvider: {
//       provide: FUNCTIONS_ORIGIN, useValue: 'http://localhost:5000'
//     }
//   },
//   appUrl: window.location.origin
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
