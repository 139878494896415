import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from './click-outside.directive';
import { IsAllowedDirective } from './is-allowed.directive';
import { UploadDirective } from './upload.directive';
import { DownloadDirective } from './download.directive';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    IsAllowedDirective,
    UploadDirective,
    DownloadDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ClickOutsideDirective,
    IsAllowedDirective,
    UploadDirective,
    DownloadDirective
  ]
})
export class DirectivesModule { }
